import { Image } from 'antd';
import React from 'react';
import './style.css'

function ContentPage(props) {

    return (
        <>
            <h1 style={{ padding: "16px 24px" }} class="ant-typography">{<div dangerouslySetInnerHTML={{ __html: props.data.judul }} />}</h1>
            <div className='content-page-img'>
                <Image alt={props.data.judul} src={(props.data.file ? props.data.main_path + props.data.file : null)} />
            </div>
            <div style={{ padding: "16px 24px" }} dangerouslySetInnerHTML={{ __html: props.data.deskripsi }} />
        </>
    )
}

export default ContentPage;