export function CapitalizeWords(str) {
    const arr = str.split(" ")
    return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
    });
}


export function GetBase64Upload(file) {
    let base64str = file;
    let base64Ar = base64str.split(",");

    if (base64Ar.length > 0) {
        base64str = base64Ar[1]
    }

    return base64str;
}

export const _getFileOriginal = async (value) => {
    let file = null
    if (value && value.length > 0) {
        if (value[0].url && (value[0].url.includes("https://") || value[0].url.includes("http://"))) {
        } else {
            file = await _getBase64(value[0].originFileObj)
        }
    }

    return file
};

export function _getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}