import { Layout, Menu, Row, Col, Button } from 'antd';
import logoMan from '../../assets/images/logo-man.png';
import './style.css'
import config from '../../Config';

const { Header } = Layout;

function doLogin() {
    window.open(config.app_external);
}

function MenuHeader() {
    return (
        <Header className='header'>
            <Row gutter={[16, 16]}>
                <Col xs={21} sm={12} className='header-left'>
                    <a href='/'>
                        <div className='header-icon'>
                            <div style={{
                                backgroundColor: `#fff`,
                                backgroundImage: `url("` + logoMan + `")`,
                                padding: "8px",
                                borderRadius: "4px",
                                width: "47px",
                                overflow: "hidden",
                                height: "47px",
                                backgroundSize: "46px",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center"
                            }}>
                            </div>
                        </div>
                        <div className='header-title'>MAN 1 GRESIK</div>
                        <div className='header-desc'>Islami, Cerdas, Unggul, Kompetitif, & Peduli Lingkungan</div>
                    </a>
                </Col>
                <Col xs={3} sm={12}>
                    <Menu
                        // icon={<MenuOutlined />}
                        // theme="dark"
                        mode="horizontal"
                        // defaultSelectedKeys={['1']}
                        items={[
                            <a href="/#pengumuman">Pengumuman</a>, <a href="/#aplikasi">Aplikasi</a>,
                            <a href="/#kegiatan">Kegiatan</a>,
                            <a href="/#prestasi">Prestasi</a>,
                            <a href="/#artikel">Artikel</a>,
                            <Button block type="primary" onClick={doLogin.bind(this)}>Login</Button>,
                        ].map((row, index) => {
                            const key = index + 1;
                            return {
                                key,
                                label: row,
                            };
                        })}
                    />
                </Col>
            </Row>
        </Header>
    );
}

export default MenuHeader;
