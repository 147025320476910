import React, { useState, useEffect } from 'react';
import { BellFilled } from '@ant-design/icons';
import { Button } from 'antd';
import axios from 'axios';
import QueueAnim from 'rc-queue-anim';

import Config from "../../../../Config";
import '../style.css'

const urlApi = Config.api
const urlPPDB = Config.app_ppdb
const headers = {
    'Content-Type': 'text/plain'
};


export default function Pengumuman(props) {
    const [pengumumanPenting, setPengumumanPenting] = useState({});
    const [ppdbIsActive, setPpdbIsActive] = useState(false);

    useEffect(() => {
        // Pengumuman Penting
        axios.post(urlApi + '/kegiatan-sekolah/status', {
            pagination: {
                current: 1,
                pageSize: 3,
            },
            filtering: {
                kategori: "Pengumuman Penting"
            },
        }, { headers }).then(function (resp) {
            // handle success
            setPengumumanPenting(resp.data.list.length > 0 ? resp.data.list[0] : {})
        }).catch(function (error) {
            // handle error
            console.log(error);
        });

        // Info PPDB
        axios.get(urlApi + '/ppdb/isactive-app', {}, { headers }).then(function (resp) {
            // handle success
            setPpdbIsActive(resp.data.data ? (resp.data.data.attr1_val === "1" ? true : false) : false)
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    }, [])

    return (
        <div className="content content-first" id='pengumuman'>
            <div className='content-home'>
                <div className='content-home-div'>
                    <QueueAnim delay={300} type={['left']} leaveReverse style={{ minHeight: 550 }}>
                        <div key={0} className='text-lebih-canggih'>🚀 Lebih canggih, dan lebih handal</div>
                        <div key={1} className='text-berbasis-teknologi'>Menuju MAN 4.0 Berbasis Teknologi Digital</div>
                        <div key={2} className='text-man-digital'>Sistem informasi MAN Digital adalah sebuah sistem  aplikasi berbasis web based yang digunakan untuk  membantu mengelola pelayanan pengelolaan data Madrasah dalam peningkatan pelayanan anggota Madrasah secara lebih cepat dan efisien, selain itu sistem ini akan membantu dan memudahkan dalam penambahan data pada aplikasi pendukung pendidikan yang resmi.</div>

                        <div key={4}>
                            <div style={{
                                width: 100,
                                height: 2,
                                background: "#10B582",
                                margin: "32px 0"
                            }}></div>
                        </div>

                        <div key={5} className='box-pengumuman'>
                            <div className='label-bell'>
                                <BellFilled style={{ fontSize: 20, margin: "4px auto", color: "#FF4D4F" }} />
                            </div>
                            <div className='text-pengumuman'>Pengumuman Penting</div>
                            <div className='text-pengumuman-desc'>{pengumumanPenting.judul ? pengumumanPenting.judul : "-"}</div>
                            <div style={{ textAlign: "right", margin: "16px 0 0 0" }}>
                                {ppdbIsActive ? <><Button type="primary"><a href={urlPPDB} target='_blank' rel='noreferrer'>PPDB {new Date().getFullYear()}</a></Button> &nbsp;</> : ""}
                                <Button onClick={props.modalAlumni.bind(this, true)}>Daftar Alumni</Button> &nbsp;
                                <Button onClick={props.modalGuestBook.bind(this, true)}>Buku Tamu</Button>
                            </div>
                        </div>
                    </QueueAnim>
                </div>
            </div>
        </div>
    )
}