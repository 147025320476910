import React, { useState, useEffect, } from 'react';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Form, Input, Radio, Row, Col, Upload, Image, Button, DatePicker, Divider, message, Result, Alert } from 'antd';
import ImgCrop from 'antd-img-crop';
import './style.css'
import axios from 'axios';
import config from '../../../../Config';
import { GetBase64Upload } from '../../../../Helper';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;
const urlApi = config.api
const headers = {
    'Content-Type': 'text/plain'
};

function Alumni(props) {

    useEffect(() => {

    }, []);

    const [form] = Form.useForm();
    const [imageList, setImageList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [values, setValues] = useState({});
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [iSuccessData, setIsSuccessData] = useState(false);
    // const [successData, setSuccessData] = useState();
    const [ddl, setDdl] = useState({
        regions: [],
        villages: [],
    });

    const getRegionDDL = (search = "") => {
        axios.post(urlApi + '/region', {
            pagination: {
                current: 1,
                pageSize: 10
            },
            search: search
        }, { headers }).then(function (resp) {
            // handle success
            setDdl({
                ...ddl,
                regions: resp.data.list
            })
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    const getVillageDDL = (districtID = "") => {
        axios.post(urlApi + '/region/' + districtID, {
            pagination: {
                current: 1,
                pageSize: 10
            },
        }, { headers }).then(function (resp) {
            // handle success
            setDdl({
                ...ddl,
                villages: resp.data.data
            })
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    const handlePreview = async file => {
        setVisible(true)
    };

    const onChangeImage = ({ fileList: newimageList }) => {
        newimageList.forEach((_, i) => {
            newimageList[i].status = "done"
        });
        console.log("newimageList", newimageList)
        setImageList(newimageList);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const beforeUpload = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/WEBP/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }
        return isJpgOrPngPdf && isLt2M;
    };

    const onChangeFile = ({ fileList: newfileList }) => {
        let isTrue = false
        newfileList.forEach((item, i) => {
            if (beforeUpload(item)) {
                isTrue = true
            }
            newfileList[i].status = "done"
        });
        if (isTrue) {
            setFileList(newfileList);
        }
    };

    const onChangeValue = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
    };
    const onSubmit = async () => {
        let foto
        let fileIjazah
        if (imageList.length > 0) {
            foto = GetBase64Upload(imageList[0].thumbUrl)
        }
        if (fileList.length > 0) {
            if (!beforeUpload(fileList[0].originFileObj)) {
                return
            }
            fileIjazah = GetBase64Upload(await getBase64(fileList[0].originFileObj));
        }

        if (!foto) {
            message.error('Foto profil wajib dipilih!');
            return
        }
        if (!fileIjazah) {
            message.error('File Ijazah wajib dipilih!');
            return
        }

        let param = {
            foto: foto ? foto : null,
            nik: values.nik ? values.nik : null,
            nama: values.nama ? values.nama : null,
            jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
            tempat_lahir: values.tempat_lahir ? values.tempat_lahir : null,
            tgl_lahir: values.tgl_lahir ? values.tgl_lahir : null,
            provinsi_id: values.provinsi_id ? values.provinsi_id : null,
            kabkot_id: values.kabkot_id ? values.kabkot_id : null,
            kecamatan_id: values.kecamatan_id ? values.kecamatan_id : null,
            kelurahan_id: values.kelurahan_id ? values.kelurahan_id : null,
            alamat: values.alamat ? values.alamat : null,
            kode_pos: values.kode_pos ? values.kode_pos : null,
            no_telp: values.no_telp ? values.no_telp : null,
            email: values.email ? values.email : null,
            tahun_masuk: values.tahun_masuk ? values.tahun_masuk : null,
            tahun_lulus: values.tahun_lulus ? values.tahun_lulus : null,
            file_ijazah: fileIjazah ? fileIjazah : null
        }

        setLoadingBtn(true)
        // console.log("param", param)
        axios.post(urlApi + '/alumni/data-diri', param, { headers }).then(function (resp) {
            // handle success
            if (resp.data.status === true) {
                message.success(resp.data.message)
                setIsSuccessData(true)
                // setSuccessData(resp.data.data)
            } else {
                message.error(resp.data.message)
            }
            setLoadingBtn(false)

        }).catch(function (error) {
            // handle error
            message.error(error.response.data.message)
            console.log(error);
            setLoadingBtn(false)

        });
    };

    useEffect(() => {
        getRegionDDL()
    }, [0])

    return (
        <div className="">
            {
                iSuccessData ?
                    <Result
                        status="success"
                        title="Terima kasih"
                        subTitle={<>Akun berhasil didaftarkan. Verifikasi akun anda sedang dilakukan. Kami akan menginformasikan status verikasi anda melalui nomor WhatsApp yang telah terdaftar.</>}
                        // extra={[
                        //     <Button type="primary" key="console">
                        //         Login yuk
                        //     </Button>,
                        // ]}
                    />
                    : <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSubmit}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={8} offset={8} style={{ textAlign: 'center' }}>
                                <ImgCrop rotate>
                                    <Upload
                                        className="image-profile"
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        maxCount={1}
                                        fileList={imageList}
                                        onChange={onChangeImage}
                                        onPreview={handlePreview}
                                    >
                                        {imageList.length === 1 ? null : <div style={{ width: "100%" }}>
                                            <PlusOutlined />
                                            <div>Upload</div>
                                        </div>}
                                    </Upload>
                                </ImgCrop>
                                {imageList.length === 1 ? <Image
                                    style={{ display: 'none' }}
                                    src={imageList[0].thumbUrl}
                                    preview={{
                                        visible,
                                        src: imageList[0].thumbUrl,
                                        onVisibleChange: value => {
                                            setVisible(value)
                                        },
                                    }}
                                /> : null}
                            </Col>
                            <Col xs={24}>
                                <Form.Item required tooltip="NIK Wajib diisi"
                                    name="nik"
                                    label="NIK"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'NIK wajib diisi',
                                        },
                                        {
                                            message: 'NIK tidak sesuai  ',
                                            pattern: new RegExp(/^[0-9]+$/)
                                        },
                                        {
                                            min: 16,
                                            message: 'Minimal 16 karakter.'
                                        },
                                        {
                                            max: 16,
                                            message: 'Maksimal 16 karakter.'
                                        },
                                    ]}
                                    onChange={(e) => {
                                        onChangeValue("nik", e.target.value)
                                    }}
                                >
                                    <Input placeholder="Masukkan nik" />
                                </Form.Item>
                                <Form.Item
                                    name="nama"
                                    label="Nama Alumni"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Nama wajib diisi',
                                        },
                                    ]}
                                    onChange={(e) => {
                                        onChangeValue("nama", e.target.value)
                                    }}
                                >
                                    <Input placeholder="Masukkan nama alumni" />
                                </Form.Item>
                                <Form.Item
                                    label="Jenis Kelamin"
                                    name="jenis_kelamin"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Jenis kelamin wajib diisi',
                                        },
                                    ]}
                                    onChange={(e) => {
                                        onChangeValue("jenis_kelamin", e.target.value)
                                    }}
                                >
                                    <Radio.Group>
                                        <Radio value={'Laki-Laki'}>Laki-Laki</Radio>
                                        <Radio value={'Perempuan'}>Perempuan</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item required
                                            name="tempat_lahir"
                                            label="Tempat Lahir"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tempat lahir wajib diisi',
                                                },
                                            ]}
                                            onChange={(e) => {
                                                onChangeValue("tempat_lahir", e.target.value)
                                            }}
                                        >
                                            <Input placeholder="Masukkan tempat lahir" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6}>
                                        <Form.Item label="Tanggal Lahir"
                                            required
                                            name="tgl_lahir"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tanggal lahir wajib diisi',
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                placeholder="Tanggal lahir"
                                                onChange={(_, dateString) => {
                                                    onChangeValue("tgl_lahir", dateString)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <Alert
                                            message="Informasi No.Telp"
                                            description="Pastikan no telp yang kamu isi, sesuai dengan nomer whatsapp kamu yang aktif."
                                            type="info"
                                            showIcon
                                            closable
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item
                                            label="No. Telp"
                                            required
                                            name="no_telp"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'No.Telp wajib diisi',
                                                },
                                                {
                                                    message: 'No.Telp tidak sesuai  ',
                                                    pattern: new RegExp(/^[0-9]+$/)
                                                },
                                            ]}
                                            onChange={(e) => {
                                                onChangeValue("no_telp", e.target.value)
                                            }}
                                        >
                                            <Input maxLength={16} placeholder='cth: 08123xxxxxx' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item
                                            label="E-Mail"
                                            required
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'E-Mail wajib diisi',
                                                },
                                                {
                                                    type: "email",
                                                    message: 'E-Mail tidak sesuai   ',
                                                },
                                            ]}
                                            onChange={(e) => {
                                                onChangeValue("email", e.target.value)
                                            }}
                                        >
                                            <Input placeholder='Masukkan email' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Kota & kecamatan">
                                    <Select
                                        showSearch
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Cari Kota & kecamatan"
                                        optionFilterProp="children"
                                        onSearch={(value) => {
                                            getRegionDDL(value)
                                        }}
                                        onChange={(value) => {
                                            let valueRegion = value.split(",");
                                            if (valueRegion.length > 0) {
                                                setValues({
                                                    ...values,
                                                    kecamatan_id: valueRegion[0],
                                                    kabkot_id: valueRegion[1],
                                                    provinsi_id: valueRegion[2]
                                                })
                                                getVillageDDL(valueRegion[0])
                                            }
                                        }}
                                    >
                                        {ddl.regions.map((item) => <Option value={`${item.kecamatan_id},${item.kabkot_id},${item.provinsi_id}`}>{item.kecamatan_nama}, {item.kabkot_nama}, {item.provinsi_nama}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Row gutter={[16, 16]}>
                                    <Col xs={18}>
                                        <Form.Item label="Kelurahan">
                                            <Select
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Cari kelurahan"
                                                onChange={(value) => {
                                                    onChangeValue("kelurahan_id", value)
                                                }}
                                            >
                                                {ddl.villages.map((item) => <Option value={`${item.id}`}>{item.nama}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Item label="Kode Pos" onChange={(e) => {
                                            onChangeValue("kode_pos", e.target.value)
                                        }}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Alamat" onChange={(e) => {
                                    onChangeValue("alamat", e.target.value)
                                }}>
                                    <TextArea rows={3} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={12}>
                                        <Form.Item
                                            label="Tahun Masuk"
                                            required
                                            name="tahun_masuk"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tahun masuk wajib diisi',
                                                },
                                            ]}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                placeholder="Tahun Masuk"
                                                picker="year"
                                                onChange={(_, dateString) => {
                                                    onChangeValue("tahun_masuk", dateString)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item
                                            label="Tahun Lulus"
                                            required
                                            name="tahun_lulus"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tahun lulus wajib diisi',
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                placeholder="Tahun Lulus"
                                                picker="year"
                                                onChange={(_, dateString) => {
                                                    onChangeValue("tahun_lulus", dateString)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Dragger
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            fileList={fileList}
                                            onChange={onChangeFile}
                                            beforeUpload={beforeUpload}
                                            maxCount={1}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Klik atau seret <b>file ijazah</b> ke area ini</p>
                                            <p className="ant-upload-hint">File yang diperbolehkan: JPG, PNG & PDF</p>
                                            <p className="ant-upload-hint">Maksimal 2MB</p>

                                        </Dragger>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} style={{ textAlign: "right", marginTop: 32 }}>
                                <Button type="primary" htmlType="submit" loading={loadingBtn}>
                                    Simpan
                                </Button>
                            </Col>
                        </Row>
                    </Form>
            }

        </div>
    )
}

export default Alumni;