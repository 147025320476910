import React from 'react';
import { Result, Button } from 'antd';

export default class error404 extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    };
  }

  render() {
    return (
      <Result
        style={{
          paddingTop: 94,
          minHeight: 900,
        }}
        status="404"
        title="404"
        subTitle="Maaf, halaman yang anda cari tidak ditemukan."
        extra={<Button type="primary"><a href="/">Back Home</a></Button>}
      />
    )
  }
}