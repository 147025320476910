import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import img1 from '../../../../assets/images/1.png';
import img2 from '../../../../assets/images/2.png';
import img3 from '../../../../assets/images/3.png';
import img4 from '../../../../assets/images/4.png';

const { Title } = Typography;

export default function AplikasiPendukung(props) {
    return (
        <>
            <div className='aplikasi-pendukung content' id='aplikasi' style={{marginTop: 0, paddingTop: 24}}>
                <div style={{
                    width: 100,
                    height: 2,
                    background: "#FFC069",
                    margin: "56px auto 16px auto"
                }}></div>

                <div className='text-aplikasi-pendukung'>Aplikasi Pendukung</div>
                <OverPack style={{ overflow: 'hidden' }} >
                    <QueueAnim key="queue"
                        leaveReverse
                        className='box-aplikasi-pendukung-div'
                    >
                        <div key="a" className="code-box-shape queue-anim-demo" >
                            <a href='https://rdm.kemenag.go.id/' target="_blank" className='link-menu' rel="noreferrer">
                                <div className='box-aplikasi-pendukung'>
                                    <img src={img1} alt="" width={100} />
                                    <Title className='title' level={5}>Rapor Digital Madrasah</Title>
                                </div>
                            </a>
                        </div>
                        <div key="b" className="code-box-shape queue-anim-demo" >
                            <a href='https://ruangpintar.man1gresik.sch.id/' target="_blank" className='link-menu' rel="noreferrer">
                                <div className='box-aplikasi-pendukung'>
                                    <img src={img2} alt="" width={100} />
                                    <Title className='title' level={5}>E-Learning Madrasah</Title>
                                </div>
                            </a>
                        </div>
                        <div key="c" className="code-box-shape queue-anim-demo" >
                            <a href='http://digilib.man1gresik.sch.id/' target="_blank" className='link-menu' rel="noreferrer">
                                <div className='box-aplikasi-pendukung'>
                                    <img src={img3} alt="" width={100} />
                                    <Title className='title' level={5}>Digital Library</Title>
                                </div>
                            </a>
                        </div>
                        <div key="d" className="code-box-shape queue-anim-demo" >
                            <a href='https://man1gresik.sch.id/' target="_blank" className='link-menu' rel="noreferrer" >
                                <div className='box-aplikasi-pendukung'>
                                    <img src={img4} alt="" width={100} />
                                    <Title className='title' level={5}>Web MAN 1 Gresik</Title>
                                </div>
                            </a>
                        </div>
                    </QueueAnim>
                </OverPack>
            </div>
        </>
    )
}