import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.min.css';

import MenuHeader from './components/layout/MenuHeader';
import MenuHeaderContent from './components/layout/MenuHeaderContent';
import Home from './components/pages/home/Home';

import Error404 from './components/pages/errors/404';

const { Content, Footer } = Layout;
// const childRef = useRef();

const App = () => (

  <Layout className="layout">
    {window.location.pathname === "" || window.location.pathname === "/" ? <MenuHeader /> : <MenuHeaderContent />}
    <Content>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>

    </Content>
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      Man 1 Gresik ©2022 Created by DDCN
    </Footer>
  </Layout>
);

export default App;
