import React, { useState, useEffect } from 'react';

import { Card, Col, Row, Empty, Modal } from 'antd';
import { Tabs, Spin } from 'antd';

import './style.css'
import Meta from 'antd/lib/card/Meta';
import axios from 'axios';
import noImage from '../../../assets/images/bg-article.jpg';
import Config from "../../../Config";


import Alumni from './modals/Alumni';
import ContentPage from './modals/ContentPage';
import Pengumuman from './sections/Pengumuman';
import AplikasiPendukung from './sections/AplikasiPendukung';
import GuestBook from './modals/GuestBook';

// import TweenOne from 'rc-tween-one';


const { TabPane } = Tabs;
const urlApi = Config.api
const headers = {
    'Content-Type': 'text/plain'
};

function EmptyData() {
    return (<Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
            height: 60,
        }}
        description={
            <span>
                Tidak ada data untuk ditampilkan
            </span>
        }
    >
    </Empty>)
}

function Home() {
    const [datakegiatan, setDatakegiatan] = useState([]);
    const [loadingkegiatan, setLoadingkegiatan] = useState(true);

    const [dataprestasisiswa, setDataprestasisiswa] = useState([]);
    const [loadingprestasisiswa, setLoadingprestasisiswa] = useState(true);

    const [dataprestasiguru, setDataprestasiguru] = useState([]);
    const [loadingprestasiguru, setLoadingprestasiguru] = useState(true);

    const [dataprestasimadrasah, setDataprestasimadrasah] = useState([]);
    const [loadingprestasimadrasah, setLoadingprestasimadrasah] = useState(true);

    const [dataartikel, setDataartikel] = useState([]);
    const [loadingartikel, setLoadingartikel] = useState(true);

    const [isModalAlumniVisible, setIsModalAlumniVisible] = useState(false);
    const modalAlumni = (value) => {
        setIsModalAlumniVisible(value);
    };

    const [isModalGuestBookVisible, setIsModalGuestBookVisible] = useState(false);
    const modalGuestBook = (value) => {
        setIsModalGuestBookVisible(value);
    };


    const [isModalContentVisible, setIsModalContentVisible] = useState(false);
    const [dataContent, setDataContent] = useState(false);
    const modalContent = (value, data) => {
        setIsModalContentVisible(value);
        setDataContent(data);
    };


    useEffect(() => {

        // Kegiatan
        axios.post(urlApi + '/kegiatan-sekolah/status', {
            "pagination": {
                "current": 1,
                "pageSize": 3,
            },
            "filtering": {
                "kategori": "Kegiatan dan publikasi"
            },
        }, { headers }).then(function (resp) {
            // handle success
            setDatakegiatan(resp.data.list)
            setLoadingkegiatan(false)
        }).catch(function (error) {
            // handle error
            setLoadingkegiatan(false)
            console.log(error);
        });

        // Prestasi siswa
        axios.post(urlApi + '/prestasi-sekolah/status', {
            "pagination": {
                "current": 1,
                "pageSize": 6,
            },
            "filtering": {
                "kategori": "Siswa"
            },
            "sorting": []
        }, { headers }).then(function (resp) {
            // handle success
            setDataprestasisiswa(resp.data.list)
            setLoadingprestasisiswa(false)
        }).catch(function (error) {
            // handle error
            setLoadingprestasisiswa(false)
            console.log(error);
        });

        // Prestasi guru
        axios.post(urlApi + '/prestasi-sekolah/status', {
            "pagination": {
                "current": 1,
                "pageSize": 6,
            },
            "filtering": {
                "kategori": "Guru"
            },
            "sorting": []
        }, { headers }).then(function (resp) {
            // handle success
            setDataprestasiguru(resp.data.list)
            setLoadingprestasiguru(false)
        }).catch(function (error) {
            // handle error
            setLoadingprestasiguru(false)
            console.log(error);
        });

        // Prestasi madrasah
        axios.post(urlApi + '/prestasi-sekolah/status', {
            "pagination": {
                "current": 1,
                "pageSize": 6,
            },
            "filtering": {
                "kategori": "Madrasah"
            },
            "sorting": []
        }, { headers }).then(function (resp) {
            // handle success
            setDataprestasimadrasah(resp.data.list)
            setLoadingprestasimadrasah(false)
        }).catch(function (error) {
            // handle error
            setLoadingprestasimadrasah(false)
            console.log(error);
        });

        // Artikel
        axios.post(urlApi + '/kegiatan-sekolah/status', {
            pagination: {
                current: 1,
                pageSize: 3,
            },
            filtering: {
                kategori: "Artikel"
            },
        }, { headers }).then(function (resp) {
            // handle success
            setDataartikel(resp.data.list)
            setLoadingartikel(false)
        }).catch(function (error) {
            // handle error
            setLoadingartikel(false)
            console.log(error);
        });

    }, []);

    return (
        <>
            <Pengumuman
                modalAlumni={modalAlumni.bind(this, true)}
                modalGuestBook={modalGuestBook.bind(this, true)}
            />
            <AplikasiPendukung />
            <div className='content' id='kegiatan'>
                <div style={{
                    width: 100,
                    height: 2,
                    background: "#FFC069",
                    margin: "56px auto 16px auto"
                }}></div>
                <div className='text-artikel'>Kegiatan dan Publikasi</div>
                <Row gutter={[16, 16]}>
                    {
                        loadingkegiatan ? <Col xs={24}>
                            <div className="spin-loading-content">
                                <Spin />
                            </div>
                        </Col> :
                            datakegiatan.length > 0 ?
                                datakegiatan.map(function (item, i) {
                                    return <Col xs={24} sm={8} key={i} title={item.judul}>
                                        <Card
                                            onClick={modalContent.bind(this, true, item)}
                                            hoverable
                                            cover={<img className='zoom' alt={item.judul} src={(item.file ? item.main_path + item.file : noImage)} />}
                                        >
                                            <Meta title={item.judul} description={<div dangerouslySetInnerHTML={{ __html: item.deskripsi.slice(0, 160) + `...` }} />} />
                                        </Card>
                                    </Col>
                                }) : <Col xs={24}><EmptyData /></Col>
                    }

                </Row>
                <div style={{
                    margin: "24px auto",
                    textAlign: "center"
                }}>
                    {/* <Button>Lainnya</Button> */}
                </div>
            </div>
            <div className='content' id='prestasi' style={{ background: "#FAFAFA", paddingTop: 24, paddingBottom: 16 }}>
                <Tabs defaultActiveKey="1" centered tabBarExtraContent={{
                    left: <div className='text-prestasi-diraih'>🏆 Prestasi Yang Diraih</div>,
                }}>
                    <TabPane tab="Siswa" key="1">
                        <Row gutter={[16, 16]}>
                            {
                                loadingprestasisiswa ? <Col xs={24}>
                                    <div className="spin-loading-content">
                                        <Spin />
                                    </div>
                                </Col> :
                                    dataprestasisiswa.length > 0 ?
                                        dataprestasisiswa.map(function (item, i) {
                                            return <Col xs={24} sm={8} key={i} title={item.judul}>
                                                <Card
                                                    onClick={modalContent.bind(this, true, item)}
                                                    hoverable
                                                    cover={<img className='zoom' alt={item.judul} src={(item.file ? item.main_path + item.file : noImage)} />}
                                                >
                                                    <Meta title={item.judul} description={<div dangerouslySetInnerHTML={{ __html: item.deskripsi.slice(0, 160) + `...` }} />} />
                                                </Card>
                                            </Col>
                                        }) : <Col xs={24}><EmptyData /></Col>
                            }
                        </Row>
                    </TabPane>
                    <TabPane tab="Guru" key="2">
                        <Row gutter={[16, 16]}>
                            {
                                loadingprestasiguru ? <Col xs={24}>
                                    <div className="spin-loading-content">
                                        <Spin />
                                    </div>
                                </Col> :
                                    dataprestasiguru.length > 0 ?
                                        dataprestasiguru.map(function (item, i) {
                                            return <Col xs={24} sm={8} key={i} title={item.judul}>
                                                <Card
                                                    hoverable
                                                    onClick={modalContent.bind(this, true, item)}
                                                    cover={<img className='zoom' alt={item.judul} src={(item.file ? item.main_path + item.file : noImage)} />}
                                                >
                                                    <Meta title={item.judul} description={<div dangerouslySetInnerHTML={{ __html: item.deskripsi.slice(0, 160) + `...` }} />} />
                                                </Card>
                                            </Col>
                                        }) : <Col xs={24}><EmptyData /></Col>
                            }
                        </Row>
                    </TabPane>
                    <TabPane tab="Madrasah" key="3">
                        <Row gutter={[16, 16]}>
                            {
                                loadingprestasimadrasah ? <Col xs={24}>
                                    <div className="spin-loading-content">
                                        <Spin />
                                    </div>
                                </Col> :
                                    dataprestasimadrasah.length > 0 ?
                                        dataprestasimadrasah.map(function (item, i) {
                                            return <Col xs={24} sm={8} key={i} title={item.judul}>
                                                <Card
                                                    hoverable
                                                    onClick={modalContent.bind(this, true, item)}
                                                    cover={<img className='zoom' alt={item.judul} src={(item.file ? item.main_path + item.file : noImage)} />}
                                                >
                                                    <Meta title={item.judul} description={<div dangerouslySetInnerHTML={{ __html: item.deskripsi.slice(0, 160) + `...` }} />} />
                                                </Card>
                                            </Col>
                                        }) : <Col xs={24}><EmptyData /></Col>
                            }
                        </Row>
                    </TabPane>
                </Tabs>
            </div >
            <div className='content artikel' id='artikel'>
                <div style={{
                    width: 100,
                    height: 2,
                    background: "#FFC069",
                    margin: "56px auto 16px auto",
                }}></div>
                <div className='text-artikel'>Artikel</div>
                <Row gutter={[16, 16]}>
                    {
                        loadingartikel ? <Col xs={24}>
                            <div className="spin-loading-content">
                                <Spin />
                            </div>
                        </Col> :
                            dataartikel.length > 0 ?
                                dataartikel.map(function (item, i) {
                                    return <Col xs={24} sm={8} key={i} title={item.judul}>
                                        <Card
                                            hoverable
                                            onClick={modalContent.bind(this, true, item)}
                                            cover={<img className='zoom' alt={item.judul} src={(item.file ? item.main_path + item.file : noImage)} />}
                                        >
                                            <Meta title={item.judul} description={<div dangerouslySetInnerHTML={{ __html: item.deskripsi.slice(0, 160) + `...` }} />} />
                                        </Card>
                                    </Col>
                                }) : <Col xs={24}><EmptyData /></Col>
                    }
                </Row>

                <div className='gerbang-utama'>
                    <div className='gerbang-utama-div'>
                        <div className='text-gerbang-utama'>Gerbang Utama untuk Siswa dan Alumni MAN 1 Gresik</div>
                    </div>
                </div>
            </div>

            <div className='content' style={{ paddingBottom: 64 }}>
                <div className='footer-man1gresik-logo'></div>
                <div className='footer-man1gresik-title'>MAN 1 Gresik</div>
                <div className='footer-man1gresik-desc'>Islami, Cerdas, Unggul, Kompetitif, & Peduli Lingkungan</div>
                <div className='footer-man1gresik-menu'>
                    <Row gutter={[16, 16]}>
                        <Col><a className='link-menu' href='#pengumuman'>Pengumuman</a></Col>
                        <Col><a className='link-menu' href='#aplikasi'>Aplikasi</a></Col>
                        <Col><a className='link-menu' href='#kegiatan'>Kegiatan</a></Col>
                        <Col><a className='link-menu' href='#prestasi'>Prestasi</a></Col>
                        <Col><a className='link-menu' href='#artikel'>Artikel</a></Col>
                    </Row>
                </div>
            </div>

            <Modal style={{ top: 40 }} width={900} title="Registrasi Alumni MAN 1 Gresik" visible={isModalAlumniVisible} onOk={modalAlumni.bind(this, false)} onCancel={modalAlumni.bind(this, false)} maskClosable={false} footer={[]}>
                {isModalAlumniVisible ? <Alumni
                    onCancel={modalAlumni.bind(this, false)}
                    onOk={modalAlumni.bind(this, false)}
                /> : null}
            </Modal>

            <Modal style={{ top: 40 }} width={600} title="Buku Tamu MAN 1 Gresik" visible={isModalGuestBookVisible} onOk={modalGuestBook.bind(this, false)} onCancel={modalGuestBook.bind(this, false)} maskClosable={false} footer={[]}>
                {isModalGuestBookVisible ? <GuestBook
                    onCancel={modalGuestBook.bind(this, false)}
                    onOk={modalGuestBook.bind(this, false)}
                /> : null}
            </Modal>


            {/* <Modal style={{ top: 80 }} title={<Tooltip placement="left" title="Open in full page"><ArrowsAltOutlined rotate={-90} className='btn-fullscreen' /></Tooltip>} */}
            <Modal title="" style={{ top: 50 }}
                width={1024}
                className='modal-content-page'
                visible={isModalContentVisible}
                onOk={modalContent.bind(this, false)}
                onCancel={modalContent.bind(this, false)}
                footer={[]}>
                {isModalContentVisible ? <ContentPage
                    data={dataContent}
                    onCancel={modalContent.bind(this, false)}
                    onOk={modalContent.bind(this, false)}
                /> : null}
            </Modal>
        </>
    );
}

export default Home;
