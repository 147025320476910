import React, { useState } from 'react';
import { Select, Form, Input, Row, Col, Upload, Image, Button, DatePicker, Divider, message, Result, Dropdown, Menu } from 'antd';
import { GetBase64Upload, _getFileOriginal } from '../../../../Helper';
import config from '../../../../Config';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CameraOutlined, CloseCircleOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Camera, FACING_MODES } from "react-html5-camera-photo";

import './style.css'
import 'react-html5-camera-photo/build/css/index.css';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;
const { TextArea } = Input;
const urlApi = config.api
const headers = {
    'Content-Type': 'text/plain'
};

const jenisTamuOptions = [
    { label: 'Kemenag', value: 'Kemenag' },
    { label: 'Kemendikbud', value: 'Kemendikbud' },
    { label: 'Wali Murid', value: 'Wali Murid' },
    { label: 'TNI/Polri', value: 'TNI/Polri' },
    { label: 'Teknisi/Jasa', value: 'Teknisi/Jasa' },
    { label: 'Sales Marketing', value: 'Sales Marketing' },
    { label: 'Lainnya', value: 'Lainnya' }
];

const tujuanBukuTamuOptions = [
    { label: 'Kepala Madrasah', value: 'Kepala Madrasah' },
    { label: 'Waka Kesiswaan', value: 'Waka Kesiswaan' },
    { label: 'Waka Kurikulum', value: 'Waka Kurikulum' },
    { label: 'Waka Humas', value: 'Waka Humas' },
    { label: 'Waka Sarpras', value: 'Waka Sarpras' },
    { label: 'Komite', value: 'Komite' },
    { label: 'LITBANG', value: 'LITBANG' },
    { label: 'Ma’had', value: 'Ma’had' },
]


function GuestBook(props) {

    const [form] = Form.useForm();
    const [values, setValues] = useState({});
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [iSuccessData, setIsSuccessData] = useState(false);
    const [isTakePhoto, setIsTakePhoto] = useState(false);
    const [capturImage, setCapturImage] = useState(null);
    const [facingMode, setFacingMode] = useState(FACING_MODES.USER);

    const onChangeValue = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
    };

    const handleGetFile = async ({ fileList }) => {
        let file = await _getFileOriginal(fileList)
        setCapturImage(file)
    }

    const onSubmit = async () => {
        if (!capturImage) {
            return message.warning("Foto tamu wajib ada.");
        }

        let file = await GetBase64Upload(capturImage)
        if (!file) {
            return message.warning("Foto tamu wajib ada.");
        }

        let param = {
            jenis_tamu: values.jenis_tamu ? values.jenis_tamu : null,
            tgl: values.tgl ? values.tgl : null,
            nama: values.nama ? values.nama : null,
            instansi: values.instansi ? values.instansi : null,
            jabatan: values.jabatan ? values.jabatan : null,
            tujuan: values.tujuan ? values.tujuan : null,
            alamat: values.alamat ? values.alamat : null,
            telepon: values.telepon ? values.telepon : null,
            keperluan: values.keperluan ? values.keperluan : null,
            keterangan: values.keterangan ? values.keterangan : null,
            foto: file ? file : null,
        }

        setLoadingBtn(true)

        // console.log("param", param)
        axios.post(urlApi + '/kaldik-kegiatan/buku-tamu', param, { headers }).then(function (resp) {
            // handle success
            if (resp.data.status === true) {
                message.success(resp.data.message)
                setIsSuccessData(true)
                form.resetFields();
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            } else {
                message.error(resp.data.message)
            }
            setLoadingBtn(false)

        }).catch(function (error) {
            // handle error
            message.error(error.response.data.message)
            setLoadingBtn(false)

        });
    };

    return (
        <div className="">
            {
                iSuccessData ?
                    <Result
                        status="success"
                        title="Terima kasih"
                        subTitle={<>Informasi data Anda berhasil disimpan, Terima Kasih.</>}
                    />
                    : <>
                        {isTakePhoto ? <div className="bg-camera"><div className="content-camera">
                            <Camera
                                style={{
                                    display: "block"
                                }}
                                idealFacingMode={facingMode}
                                // isImageMirror={true}
                                isFullScreen={true}
                                isMaxResolution={true}
                                sizeFactor={0.5}
                                isSilentMode={true}
                                onTakePhoto={(img) => {
                                    setCapturImage(img)
                                    setIsTakePhoto(false)
                                }}
                                imageType="jpg"
                                imageCompression={0}
                                onCameraError={(err) => {
                                    setIsTakePhoto(false)
                                    message.warn("Mohon maaf sedang ada kesalahan  pada kamera, silahkan pilih file.")
                                }}
                            />
                            <SyncOutlined className='icon-change-camera' onClick={() => {
                                setFacingMode(facingMode === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT)
                            }} />
                            <CloseCircleOutlined className='icon-close-camera' onClick={() => {
                                setIsTakePhoto(false)
                            }} />
                        </div></div> : ""}
                        {!capturImage ? <>
                            <Result
                                icon={<CameraOutlined />}
                                title="Upload Foto Tamu"
                                subTitle="Maaf, untuk melanjutkan Anda harus upload foto tamu terlebih dahulu."
                                extra={<Dropdown trigger={['click']} overlay={<Menu>
                                    <Menu.Item key={0} onClick={() => {
                                        setIsTakePhoto(true)
                                    }}><CameraOutlined /> Buka Kamera</Menu.Item>
                                    <Menu.Item key={1} ><Upload maxCount={1} showUploadList={false} onChange={handleGetFile}><UploadOutlined /> Pilih File</Upload></Menu.Item>
                                </Menu>}>
                                    <Button type="primary">Ubah Foto <CameraOutlined style={{ fontSize: 11 }} /></Button>
                                </Dropdown>}
                            />
                        </>
                            : <Form
                                form={form}
                                layout="vertical"
                                onFinish={onSubmit}
                            >

                                <Row gutter={[16, 0]}>
                                    <Col xs={24} style={{ textAlign: "center" }}>
                                        <Image
                                            width={200}
                                            src={capturImage}
                                        />
                                    </Col>
                                    <Col xs={24} style={{ textAlign: "center" }}>
                                        <Divider />
                                        <Dropdown trigger={['click']} overlay={<Menu>
                                            <Menu.Item key={0} onClick={() => {
                                                setIsTakePhoto(true)
                                            }}><CameraOutlined /> Buka Camera</Menu.Item>
                                            <Menu.Item key={1} ><Upload maxCount={1} showUploadList={false} onChange={handleGetFile}><UploadOutlined /> Pilih File</Upload></Menu.Item>
                                        </Menu>}>
                                            <Button type="primary">Ubah Foto <CameraOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>
                                        <br />
                                        <br />
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="Nama Tamu *">
                                            <Input
                                                name="nama"
                                                placeholder="Nama tamu"
                                                onChange={(e) => {
                                                    onChangeValue("nama", e.target.value)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="No.Telp *">
                                            <Input
                                                name="telepon"
                                                placeholder="Np.telp"
                                                onChange={(e) => {
                                                    onChangeValue("telepon", e.target.value)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="Jenis Tamu *">
                                            <Select
                                                allowClear
                                                name="jenis_tamu"
                                                placeholder="Jenis tamu"
                                                onChange={(e, newValue) => {
                                                    onChangeValue("jenis_tamu", newValue ? newValue.value : null)
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                {jenisTamuOptions.map((row, i) => {
                                                    return <Option key={i} value={row.value}>{row.label}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>

                                        <Form.Item label="Tanggal *">
                                            <DatePicker format={dateFormat} style={{ width: "100%" }}
                                                onChange={(e, val) => {
                                                    onChangeValue("tgl", val)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="Asal Instansi *">
                                            <Input
                                                name="instansi"
                                                placeholder="Asal instansi"
                                                onChange={(e) => {
                                                    onChangeValue("instansi", e.target.value)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="Jabatan *">
                                            <Input
                                                name="jabatan"
                                                placeholder="Jabatan"
                                                onChange={(e) => {
                                                    onChangeValue("jabatan", e.target.value)
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="Tujuan *">
                                            <Select
                                                allowClear
                                                name="tujuan"
                                                placeholder="Tujuan"
                                                onChange={(e, newValue) => {
                                                    onChangeValue("tujuan", newValue ? newValue.value : null)
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                {tujuanBukuTamuOptions.map((row, i) => {
                                                    return <Option key={i} value={row.value}>{row.label}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="Alamat">
                                            <TextArea
                                                rows={2}
                                                placeholder="Alamat"
                                                onChange={(e) => {
                                                    onChangeValue("alamat", e.target.value)
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Keperluan *">
                                            <TextArea
                                                rows={2}
                                                placeholder="Keperluan"
                                                onChange={(e) => {
                                                    onChangeValue("keperluan", e.target.value)
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Keterangan">
                                            <TextArea
                                                rows={2}
                                                placeholder="Keterangan"
                                                onChange={(e) => {
                                                    onChangeValue("keterangan", e.target.value)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} style={{ textAlign: "right" }}>
                                        <Divider />
                                        <Button type="primary" htmlType="submit" loading={loadingBtn}>Simpan</Button>
                                    </Col>
                                </Row>

                            </Form>
                        }
                    </>}

        </div>
    )
}

export default GuestBook;